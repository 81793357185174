'use client';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { $displayAdStarted, AdProps, displayAdEmptyUnmounted, displayAdEmptyUpdated } from './model';
import { useUnit } from 'effector-react';
import { AnalyticsContext } from '@/components/Layout';
const ADS_CONTENT_LOADED_CHECK_TO = 3000;
const Ad = (props: AdProps) => {
    const { adOptions, className } = props;
    const [isClient, setIsClient] = useState(false);
    const adRef = useRef<(HTMLElement & { coreService: any }) | null>(null);
    const adsEmptyHandler = useUnit(displayAdEmptyUpdated);
    const onUnmount = useUnit(displayAdEmptyUnmounted);
    const adStarted = useUnit($displayAdStarted);
    const { AITracks, AIReadyAsDep } = useContext(AnalyticsContext);
    const containerRef = useRef(null);
    const id = props.adOptions['data-id'];
    useEffect(() => {
        setIsClient(true);
    }, []);

    useEffect(() => {
        const containerElement = containerRef.current;
        if (!containerElement) return;

        let componentFound = false;

        const findComponent = () => {
            const component = (containerElement as HTMLDivElement).querySelector('display-ad-component');
            if (component && !componentFound) {
                componentFound = true;
                setupComponent(component as HTMLElement & { coreService: any });
            }
        };

        const setupComponent = (elem: HTMLElement & { coreService: any }) => {
            if (elem.coreService) {
                elem.coreService.onShowGTMEvent = (
                    ads: string[],
                    isRefresh: boolean,
                    eventDetail: {
                        isEmpty: boolean;
                        size: Array<[number, number]>;
                        cpm: number;
                    }
                ) => {
                    ads.forEach((ad) => {
                        adsEmptyHandler({ id: ad, empty: eventDetail.isEmpty });
                        !eventDetail.isEmpty && AITracks.displayAd(adRef?.current, isRefresh, true); // refreshed show track
                    });
                };
            } else {
                // If coreService is not available yet, try again after a short delay
                setTimeout(() => setupComponent(elem), 100);
            }
        };

        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    findComponent();
                }
            }
        });

        // Start observing the container for changes
        observer.observe(containerElement, {
            childList: true,
            subtree: true,
        });

        // Check if the component already exists (in case it was added before the observer was attached)
        findComponent();

        // Use customElements.whenDefined as a fallback
        customElements.whenDefined('display-ad-component').then(() => {
            findComponent();
        });

        return () => {
            onUnmount(id);
            observer.disconnect();
        };
    }, [adsEmptyHandler, onUnmount, id]);

    useEffect(() => {
        if (!adStarted || !AIReadyAsDep) {
            return;
        }
        setTimeout(() => {
            AITracks.displayAd(adRef?.current, false);
        }, ADS_CONTENT_LOADED_CHECK_TO);
    }, [AIReadyAsDep, AITracks, adStarted]);

    return (
        <div data-testid="ad-container" className={className} ref={containerRef}>
            {((adStarted && isClient) || id === 'test-ad') && (
                <div data-element-description={adOptions['data-id']} data-testid={'display-ad'}>
                    {/* @ts-ignore */}
                    <display-ad-component ref={adRef} {...adOptions} key={adOptions['data-id']} />
                </div>
            )}
        </div>
    );
};
Ad.displayName = 'Ad';

export default Ad;
