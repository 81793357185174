'use client';

import ReactDOM from 'react-dom';
import { LocalStorageService } from '@/shared/utils/local-storage';
import { useSearchParams } from 'next/navigation';
import React, { createContext, ReactNode, useEffect, useRef } from 'react';
import { AITracksDefault, useAnalytics } from '@/features/analytics/AI/AITracks';
import { AnalyticsPageTypes, resetCurrentPage, setCurrentPage } from '@/app.model';
import { IModularPage } from '@/shared/api/pages';
import { BURGER_INPUT_ID } from '@/components/SideBar';
import { RawCarouselPromo, TCarouselPromo } from '@/features/carouselPromo/model';
import { WEB_COMPONENTS_NAMES } from '@/shared/app';
import { useWebVitalsFx } from '@/features/web-vitals/useWebVitalsFx';
import { useUnit } from 'effector-react';

function isCarouselPromo(content: RawCarouselPromo): content is RawCarouselPromo {
    return content?.__component === WEB_COMPONENTS_NAMES.CarouselPromo;
}

function getFirstPromoCarouselSlideImage(page: IModularPage | undefined): string | undefined {
    const firstContent = page?.content[0] as RawCarouselPromo;
    if (isCarouselPromo(firstContent as RawCarouselPromo) && firstContent.carousels.length > 0) {
        const firstCarousel = firstContent.carousels[0] as TCarouselPromo;
        return firstCarousel.slides[0]?.promos_library?.image?.url;
    }
    return undefined;
}

type TProps = Readonly<{
    classnames?: string;
    style?: React.CSSProperties;
    children: ReactNode;
    aiPageType: AnalyticsPageTypes;
    genericPageData?: IModularPage;
}>;

export const AnalyticsContext = createContext<{ AITracks: any; AIReadyAsDep: boolean }>({
    AITracks: AITracksDefault,
    AIReadyAsDep: false,
});

const AnalyticsContextProvider = AnalyticsContext.Provider;

const Layout = (props: TProps) => {
    const { classnames, style, children, aiPageType, genericPageData } = props;

    const LCPimage = getFirstPromoCarouselSlideImage(genericPageData);

    if (LCPimage) {
        ReactDOM.preload(LCPimage, { as: 'image' });
    }

    const searchParams = useSearchParams();
    const isSentryQp = searchParams.get('evo:sentry_test') === 'true';
    const isSentryLs = LocalStorageService.getItem('sentry_test') === 'true';
    const pageType = aiPageType ?? AnalyticsPageTypes.UNKNOWN;

    const [AITracks, AIReadyAsDep] = useAnalytics(genericPageData, pageType);
    const setCurrentPageFx = useUnit(setCurrentPage);

    useEffect(() => {
        setCurrentPageFx(aiPageType);

        return () => {
            resetCurrentPage();
        };
    }, [aiPageType]);

    useWebVitalsFx(AITracks, AIReadyAsDep);

    useEffect(() => {
        AIReadyAsDep && AITracks.PageView();
    }, [AIReadyAsDep, AITracks]);

    useEffect(() => {
        window.addEventListener('keyup', accessibilityEnterKeyForFocusedHandler);
        function accessibilityEnterKeyForFocusedHandler(ev: Event) {
            if ((ev as any)?.key === 'Enter') {
                const focusedElement: any = ev?.target;
                if (focusedElement?.id === BURGER_INPUT_ID) {
                    // input checkbox, e.g. burger
                    focusedElement.checked = !focusedElement.checked;
                    focusedElement.setAttribute('checked', focusedElement.checked ? 'false' : 'true');
                    focusedElement?.focus?.();
                } else if (focusedElement) {
                    // links, buttons, cards, etc.
                    focusedElement?.click?.();
                }
            }
        }
        return () => {
            window.removeEventListener('keyup', accessibilityEnterKeyForFocusedHandler);
        };
    }, []);

    const handleButtonClick = () => {
        fetch('/api/sentry?throw=true');
        throw new Error('test sentry frontend error ' + Math.random().toFixed(10));
    };
    const mainClassnames = `${classnames ? classnames : ''} ${pageType === 'gamepage' ? 'gamepage' : ''}`.trim();
    return (
        <>
            <AnalyticsContextProvider value={{ AITracks, AIReadyAsDep }}>
                <div>
                    {(isSentryLs || isSentryQp) && (
                        <button style={{ marginLeft: '50%' }} onClick={handleButtonClick}>
                            test sentry frontend error
                        </button>
                    )}
                    <main className={mainClassnames} style={style}>
                        {children}
                    </main>
                </div>
            </AnalyticsContextProvider>
        </>
    );
};

export default Layout;
