import { createEvent, createStore } from 'effector';
import { IModularPageBase } from '../api/pages';

export enum PageTypes {
    Home = 'home',
    Category = 'category',
    AllCategories = 'allCategories',
    Game = 'games',
    NotFound = 'notFound',
    Profile = 'profile',
    PrivacyPolicy = 'privacyPolicy',
    PrivacyPolicyCalifornia = 'privacyPolicyCalifornia',
    Subscription = 'subscription',
    AboutUs = 'aboutUs',
    GiftCard = 'giftCard',
    SiteMap = 'siteMap',
    CoBranded = 'coBranded',
    BlogArchive = 'blogArchive',
    BlogPost = 'blogPost',
}
export const pageStarted = createEvent<{
    page: IModularPageBase;
    pageCtx: unknown;
}>();

export const $currentPage = createStore<IModularPageBase | null>(null).on(
    pageStarted,
    (_, { page }) => page ?? 'unknown_page'
);

export type TLink = {
    href: string;
    label: string;
    target: '_blank' | '_self' | '';
    icon: { url: string };
};

export enum WEB_COMPONENTS_NAMES {
    Carousel = 'web-components.carousel',
    AdComponent = 'web-components.ad-component',
    IframeBlock = 'web-components.iframe-block',
    CarouselPromo = 'web-components.promo-carousels',
    HorizontalPills = 'web-components.horizontal-generic-links',
    RecentlyPlayed = 'web-components.recently-played'
}
